import './Budgets.css';

const Budgets = () => {
  return (
    <div>
      <h4>Budgets</h4>
    </div>
  )
}

export default Budgets
